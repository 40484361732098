<template>
	<div class="container">
		<div class="overview">
			<div class="text">
				<div class="line"></div>
				<p class="title">小门神电子哨兵·人脸识别测温一体机</p>
				<p class="subTitle">3D人脸识别，超高安全级别</p>
			</div>
			
			<div class="img-text">
				<img src="../../assets/imgs/aio1.png" alt="">
				<p class="content">“电子哨兵”由数据管理平台和监控终端组成，可通过刷身份证+人脸核验用户身份，实现健康吗、疫苗接种、核酸信息、社区身份信息等智能化识别与核验</p>
			</div>
		</div>
		
		<div class="introduce">
			<div class="text">
				<div>
					<p class="name">精准测温</p>
					<p style="margin: 28px 0 12px;" class="content">红外精准测温·高温预警</p>
					<p class="content">活体人脸识别·口罩检测识别</p>
				</div>
				<div>
					<p class="name">权威对接</p>
					<p style="margin: 28px 0 12px;" class="content">人脸测温识别一体机可对接公安网</p>
					<p class="content">人脸测温识别一体机可对接健康吗</p>
				</div>
				<div>
					<p class="name">软件加硬件综合治理</p>
					<p style="margin: 28px 0 12px;" class="content">配备对应管理系统对设备和人员的管理与检测</p>
					<p class="content">从软件到硬件实现综合治理</p>
				</div>
			</div>
			
			<img src="../../assets/imgs/aio2.png" alt="">
		</div>
		
		<div style="background-color: rgb(244, 248, 255);text-align: center;">
			<img style="width: 1200px;" src="../../assets/imgs/the3.png" alt="">
		</div>
		
		<div class="measurement">
			<div class="text">
				<div class="line"></div>
				<p class="title">精准测量</p>
				<p class="subTitle">领先的红外人脸识别算法以及彩色人脸识别算法，识别>99.8%，并且在实际使用过程中尚未出现误识别。</p>
			</div>
			
			<img src="../../assets/imgs/the4.png" alt="">
		</div>
		
		<div class="installation">
			<div class="text">
				<div class="line"></div>
				<p class="title">多场景安装</p>
				<p class="subTitle">测温设备支持多种安装方式</p>
			</div>
			
			<img src="../../assets/imgs/the5.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style scoped>
.line {
	width: 61px;
	height: 7px;
	background: #184BAB;
}

.title {
	height: 36px;
	font-size: 36px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-top: 14px;
}

.subTitle {
	height: 16px;
	font-size: 16px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
}
.overview {
	width: 1200px;
	margin: 0 auto;
	padding: 126px 117px 0;
	box-sizing: border-box;
}
.overview .text {
	position: relative;
}

.overview .text .subTitle {
	margin-top: 8px;
}
.overview .img-text {
	display: flex;
	margin-top: 87px;
	justify-content: center;
	
}

.overview .img-text .content {
	width: 491px;
	font-size: 16px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
	margin-left: 60px;
	position: relative;
	top: 36px;
	text-align: right;
}

.introduce{
	margin: 197px auto 111px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.introduce .text {
	margin-right: 54px;
	
}

.introduce .text div:not(:last-child) {
	margin-bottom: 70px;
}

.introduce .text div .name {
	height: 22px;
	font-size: 22px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.introduce .text div .name::before {
	content: '●';
	color: #245ECB;
	margin-right: 10px;
	/* margin-left: -22px; */
}

.introduce .text div .content {
	height: 18px;
	font-size: 18px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #3A3A3A;
	margin-left: 22px !important;
}

.measurement {
	margin: 57px auto 0;
	width: 1200px;
	padding-left: 119px;
	box-sizing: border-box;
	display: flex;
}

.measurement .text {
	margin-right: 105px;
}

.measurement .text .subTitle {
	width: 511px;
	font-size: 18px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #3A3A3A;
	line-height: 22px;
	margin-top: 18px;
}

.installation {
	margin: 149px auto 88px;
	width: 1200px;
	padding-left: 119px;
	box-sizing: border-box;
}

.installation .text .subTitle {
	margin: 22px 0 44px;
}


</style>